<template>
  <div class="agendar mx-2 my-2">
    <v-row dense>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>Agendar cita</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined v-if="centro">
                  <v-card-title>
                    Centro: {{ centro.centro }}
                    <v-spacer></v-spacer>
                    <v-btn @click="close()" color="black" dark small>
                      Salir
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <p>
                      <strong>Sede:</strong>
                      {{ centro.sede }}
                      <br />
                      <strong>Contaco:</strong>
                      {{ centro.telefono }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="6" order-md="2" order-sm="2">
                <v-card elevation="0" outlined v-if="cliente">
                  <v-card-title>{{ cliente.nombre }}</v-card-title>
                  <v-card-text>
                    <p>
                      <strong>ID / CC:</strong>
                      {{ cliente.cedula }}
                      <br />
                      <strong>Correo:</strong>
                      {{ cliente.email }}
                      <br />
                      <strong>Puntos:</strong>
                      {{ cliente.puntos }}
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="6" order-md="1" order-sm="1">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" md="6" sm="12">
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              outlined
                              rounded
                              dense
                              label="Fecha"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            scrollable
                            v-model="date"
                            locale="es-co"
                            color="primary"
                            :min="min"
                            first-day-of-week="1"
                            @input="menu = false"
                            @change="loadHoras()"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6" sm="12">
                        <v-autocomplete
                          dense
                          outlined
                          rounded
                          v-model="servicio"
                          :loading="isLoadingS"
                          append-icon="mdi-magnify"
                          item-text="servicio"
                          :items="servicios"
                          :filter="customFilterServicios"
                          label="Selecciona un servicio"
                          @change="loadProfesionales()"
                          return-object
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6" sm="6" class="d-none d-md-inline">
                        <div align="center">
                          <v-tooltip top color="black">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon color="primary"
                                  >mdi-checkbox-blank-circle-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <span>Disponible</span>
                          </v-tooltip>
                          <v-tooltip top color="black">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon color="purple">mdi-circle</v-icon>
                              </v-btn>
                            </template>
                            <span>Ocupado</span>
                          </v-tooltip>
                          <v-tooltip top color="black">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon color="grey">mdi-circle</v-icon>
                              </v-btn>
                            </template>
                            <span>Bloqueado</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                        sm="6"
                        class="d-block d-md-none d-xl-none"
                      >
                        <v-btn small color="primary" outlined>Disponible</v-btn>
                        <v-btn small color="purple" dark class="mx-1"
                          >Ocupado</v-btn
                        >
                        <v-btn small color="grey" dark>Bloqueado</v-btn>
                      </v-col>
                      <v-col cols="12" md="6" sm="6" align="center">
                        <h2>
                          <strong>Duración:</strong>
                          {{ format_duracion }}
                        </h2>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                sm="12"
                md="12"
                cols="12"
                v-if="centro && apertura.inicio == null"
              >
                <v-alert type="warning">
                  El centro no cuenta con agenda para este dia
                </v-alert>
              </v-col>
              <v-col
                sm="12"
                md="12"
                cols="12"
                style="display: flex;
        overflow: hidden;
        overflow-x: scroll;"
                v-else
              >
                <!-- contenedor agendas -->
                <div class="container-agenda">
                  <!-- contenedor profesionales -->
                  <div
                    class="header-prof scroll-p"
                    id="scroll-p"
                    @mousewheel="mouseEvent"
                    @scroll="scrollEvent"
                  >
                    <v-col v-if="headerAgenda.length == 0 && servicio">
                      <div class="text-center">
                        <v-progress-circular
                          :size="50"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                    </v-col>
                    <v-col
                      v-for="(profesional, index) in headerAgenda"
                      :key="index"
                      v-else
                    >
                      <div align="center">
                        <div style="width: 100px;">
                          <v-avatar>
                            <img
                              :src="
                                centro.url + 'profesionales/' + profesional.foto
                              "
                            />
                          </v-avatar>
                          <br />
                          <strong>
                            {{ profesional.apodo }}
                          </strong>
                        </div>
                      </div>
                    </v-col>
                  </div>
                  <!-- contenedor horas por profesional -->
                  <div class="horas scroll-h" id="scroll-h">
                    <v-col v-if="agendaFinal.length == 0 && servicio">
                      <div class="text-center">
                        <v-progress-circular
                          :size="50"
                          color="primary"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                    </v-col>
                    <v-col
                      v-for="(item, index) in agendaFinal"
                      :key="index"
                      v-else
                    >
                      <div
                        align="center"
                        class="mt-2"
                        v-for="(hora, i) in item"
                        :key="i"
                      >
                        <v-menu
                          :close-on-content-click="false"
                          :nudge-width="200"
                          :ref="`menu_${index}_${i}`"
                          v-if="hora.estado === 0"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-on="on"
                              v-bind="attrs"
                              width="100px"
                              outlined
                              small
                              color="primary"
                              @click="profesionalSelected(hora.p_id)"
                            >
                              {{ hora.hora }}
                            </v-btn>
                          </template>
                          <v-card>
                            <v-list>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Cita agendada para:
                                  </v-list-item-title>
                                  <strong>
                                    {{ moment(date).format("ll") }}
                                  </strong>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Hora:
                                  </v-list-item-title>
                                  <strong>
                                    {{ hora.hora }}
                                  </strong>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Profesional:
                                  </v-list-item-title>
                                  <strong v-if="profesional != null">
                                    {{ profesional.apodo }}
                                  </strong>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    Notas:
                                  </v-list-item-title>
                                  <v-textarea
                                    no-resize
                                    rows="5"
                                    @input="saveNota"
                                  >
                                  </v-textarea>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                            <v-card-actions v-if="profesional != null">
                              <v-spacer></v-spacer>
                              <v-btn
                                small
                                :dark="!loading"
                                :color="!loading ? 'black' : 'gray'"
                                :loading="loading"
                                :disabled="loading"
                                @click="tomarCita(hora, index, i)"
                              >
                                Agendar
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                        <v-btn
                          dark
                          width="100px"
                          small
                          color="purple"
                          v-if="hora.estado === 1"
                        >
                          {{ hora.hora }}
                        </v-btn>
                        <v-btn
                          dark
                          width="100px"
                          small
                          color="grey"
                          v-if="hora.estado === 2"
                        >
                          {{ hora.hora }}
                        </v-btn>
                      </div>
                    </v-col>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" top right :color="color">
      {{ alert }}
      <v-btn
        text
        @click="
          snackbar = false;
          alert = '';
        "
        >X</v-btn
      >
    </v-snackbar>
  </div>
</template>
<script>
import axios from "axios";
import * as moment from "moment";
moment.locale("es");
export default {
  data: () => ({
    snackbar: false,
    color: "success",
    alert: "",
    centro: null,
    cliente: null,
    menu: false,
    date: moment().format("YYYY-MM-DD"),
    min: moment().format("YYYY-MM-DD"),
    servicio: null,
    isLoadingS: false,
    servicios: [],
    profesionales: [],
    profesional: null,
    loading: false,
    horas: {
      agenda: [],
      bloqueos: [],
    },
    cita: {
      cliente: "",
      inicio: "",
      fin: "",
      date: "",
      profesional: "",
      servicio: "",
      nota: "",
      horas: [],
    },
  }),
  methods: {
    loadCliente() {
      axios({
        method: "GET",
        url: "/detalle_cliente_web",
        params: {
          id: this.$route.params.id,
          token: this.$route.params.data,
        },
        responseType: "json",
      })
        .then((response) => {
          if (response.data.success) {
            this.cliente = response.data.data;
            this.centro = response.data.centro;
            this.centro.horarios = JSON.parse(this.centro.horarios);
          }
        })
        .catch((error) => {
          if (error.response.status) {
            this.$router.push("/loginClientes/" + this.$route.params.data);
          }
        });
    },
    loadHoras() {
      axios({
        method: "GET",
        url: "/tiempo_agendado_web",
        params: {
          date: this.date,
          token: this.$route.params.data,
        },
        responseType: "json",
      }).then((response) => {
        if (response.data.success) {
          this.horas = response.data.data;
        }
      });
    },
    loadServicios() {
      this.isLoadingS = true;
      axios({
        method: "GET",
        url: "/servicios_activos_web",
        params: {
          token: this.$route.params.data,
        },
        responseType: "json",
      })
        .then((response) => {
          if (response.data.success) {
            this.servicios = response.data.data;
            this.servicios = this.servicios.map((servicio) => {
              servicio.duracion = parseInt(servicio.duracion);
              return servicio;
            });
          }
        })
        .finally(() => (this.isLoadingS = false));
    },
    loadProfesionales() {
      axios({
        method: "GET",
        url: "/profesional_servicios_web",
        params: {
          id: this.servicio.id_activo,
          token: this.$route.params.data,
        },
        responseType: "json",
      }).then((response) => {
        if (response.data.success) {
          this.profesionales = response.data.data;
        }
      });
    },
    customFilterServicios(item, queryText, itemText) {
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };
      const text = removeAccents(queryText);
      const textOne = item.servicio.toLowerCase();
      const searchText = text.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    profesionalSelected(id) {
      let index = this.headerAgenda.findIndex((element) => element.id === id);
      this.profesional = this.headerAgenda[index];
    },
    saveNota(input) {
      this.cita.nota = input;
    },
    tomarCita(hora, index, i) {
      this.loading = true;
      this.cita.cliente = this.$route.params.id;
      this.cita.inicio = moment(hora.hora, "h:mm A").format("HH:mm");
      this.cita.fin = moment(hora.hora, "h:mm A")
        .add(this.servicio.duracion, "minutes")
        .format("HH:mm");
      this.cita.date = this.date;
      this.cita.profesional = hora.p_id;
      this.cita.servicio = this.servicio.id_activo;
      this.cita.nota = this.cita.nota == "" ? null : this.cita.nota;
      this.cita.horas = this.iteracion(this.cita.inicio, this.cita.fin);

      let hora_max = moment(
        moment(this.apertura.fin, "HH:mm")
          .add(-parseInt(this.servicio.duracion), "minutes")
          .format("HH:mm:ss"),
        "HH:mm:ss"
      );
      if (moment(this.cita.inicio, "HH:mm:ss").isSameOrBefore(hora_max)) {
        axios({
          method: "POST",
          url: "/tomar_cita_web",
          data: {
            data: this.cita,
            token: this.$route.params.data,
          },
          responseType: "json",
        })
          .then((response) => {
            if (response.data.success) {
              this.alert = response.data.data;
              this.color = "success";
              this.snackbar = true;
              this.$refs[`menu_${index}_${i}`][0].isActive = false;
              this.loadHoras();
              this.cita.nota = "";
            }
          })
          .catch((error) => {
            this.alert = error.response.data.data;
            this.color = "error";
            this.snackbar = true;
          })
          .finally(() => (this.loading = false));
      } else {
        this.alert = 'La hora seleccionada es excede el tiempo de agenda del centro';
        this.color = "error";
        this.snackbar = true;
      }
    },
    moment(data) {
      return moment(data);
    },
    calculateRange(start, end, minutes) {
      let range = [];
      for (
        let hour = moment(start);
        hour.isBefore(end);
        hour.add(minutes, "minutes")
      ) {
        range.push(moment(hour));
      }
      range.push(moment(end));
      return range;
    },
    iteracion(inicio, fin) {
      let start = moment(inicio, "HH:mm");
      let end = moment(fin, "HH:mm").add(-this.centro.agenda, "minutes");
      let rangeArray = this.calculateRange(
        start,
        end,
        parseInt(this.centro.agenda)
      ).map((hora) => {
        return hora.format("HH:mm");
      });
      return rangeArray;
    },
    close() {
      this.$router.push("/loginClientes/" + this.$route.params.data);
    },
    mouseEvent(event) {
      let targetH = document.getElementsByClassName("scroll-h")[0];
      let HtoLeft = event.deltaY < 0 && targetH.scrollLeft > 0;
      let HtoRight =
        event.deltaY > 0 &&
        targetH.scrollLeft < targetH.scrollWidth - targetH.clientWidth;
      if (HtoLeft || HtoRight) {
        event.preventDefault();
        targetH.scrollLeft += event.deltaY;
      }

      let targetP = document.getElementsByClassName("scroll-p")[0];
      let PtoLeft = event.deltaY < 0 && targetP.scrollLeft > 0;
      let PtoRight =
        event.deltaY > 0 &&
        targetP.scrollLeft < targetP.scrollWidth - targetP.clientWidth;
      if (PtoLeft || PtoRight) {
        event.preventDefault();
        targetP.scrollLeft += event.deltaY;
      }
    },
    scrollEvent(event) {
      let timeStamp = event.timeStamp;
      let id = event.target.id;
      let target;
      if (id == "scroll-h") {
        target = document.getElementsByClassName("scroll-p")[0];
      } else {
        target = document.getElementsByClassName("scroll-h")[0];
      }
      event.preventDefault();
      target.scrollLeft = event.target.scrollLeft;
      target.timeStamp = timeStamp;
    },
  },
  created() {
    this.loadCliente();
    this.loadHoras();
    this.loadServicios();
  },
  computed: {
    apertura() {
      let horarios = { inicio: null, fin: null };
      if (moment(this.date).isoWeekday() == 7) {
        horarios.inicio = this.centro ? this.centro.horarios.inicio_d : null;
        horarios.fin = this.centro ? this.centro.horarios.fin_d : null;
      }

      if (moment(this.date).isoWeekday() == 6) {
        horarios.inicio = this.centro ? this.centro.horarios.inicio_s : null;
        horarios.fin = this.centro ? this.centro.horarios.fin_s : null;
      }

      if (moment(this.date).isoWeekday() <= 5) {
        horarios.inicio = this.centro ? this.centro.horarios.inicio_lv : null;
        horarios.fin = this.centro ? this.centro.horarios.fin_lv : null;
      }
      return horarios;
    },
    agenda() {
      let actual = moment(moment().format("HH:mm"), "HH:mm");
      let start = moment(this.apertura.inicio, "HH:mm");

      if (this.date == moment().format("YYYY-MM-DD")) {
        if (actual.isSameOrBefore(moment(this.apertura.inicio, "HH:mm"))) {
          start = moment(this.apertura.inicio, "HH:mm");
        } else {
          let rounded = parseInt(this.centro.agenda) - (actual.minute() % 30);
          let resul = moment(actual)
            .add(rounded, "minutes")
            .format("HH:mm");
          start = moment(resul, "HH:mm");
        }
      } else {
        start = moment(this.apertura.inicio, "HH:mm");
      }
      let end = moment(this.apertura.fin, "HH:mm");
      let rangeArray = this.calculateRange(
        start,
        end,
        parseInt(this.centro.agenda)
      ).map((hora) => {
        return {
          hora: hora.format("h:mm A"),
          estado: 0,
        };
      });

      return rangeArray;
    },
    profesionalesList() {
      const profesionales = this.profesionales.map((prof) => {
        const agenda = this.agenda.map((ag) => {
          return {
            hora: ag.hora,
            estado: 0,
            p_id: prof.id,
            date: this.date,
          };
        });
        return {
          profesional: prof.nombre,
          id: prof.id,
          foto: prof.foto,
          apodo: prof.apodo,
          agenda: agenda,
        };
      });
      return profesionales;
    },
    headerAgenda() {
      return this.profesionalesList.map((prof) => {
        return {
          profesional: prof.profesional,
          id: prof.id,
          foto: prof.foto,
          apodo: prof.apodo,
        };
      });
    },
    agendaFinal() {
      const agenda = this.profesionalesList.map((prof) => {
        this.horas.agenda
          .filter((hora) => {
            return hora.profesional_id == prof.id;
          })
          .forEach((hora) => {
            const inicio = moment(hora.inicio, "HH:mm:ss").format("HH:mm");
            const fin = moment(hora.fin, "HH:mm:ss").format("HH:mm");
            this.iteracion(inicio, fin).forEach((hr) => {
              prof.agenda.forEach((ag) => {
                if (
                  moment(hr, "HH:mm").format("h:mm A") === ag.hora &&
                  hora.fecha == ag.date
                ) {
                  ag.estado = 1;
                }
              });
            });
          });

        this.horas.bloqueos
          .filter((hora) => {
            return hora.profesional_id == prof.id;
          })
          .forEach((hora) => {
            prof.agenda.forEach((ag) => {
              if (
                moment(hora.hora, "HH:mm:ss").format("h:mm A") === ag.hora &&
                hora.fecha == ag.date
              ) {
                ag.estado = 2;
              }
            });
          });
        return prof.agenda;
      });
      return agenda;
    },
    format_duracion() {
      let duracion = this.servicio ? this.servicio.duracion : 0;
      let h = (duracion / 60) | 0;
      let m = duracion % 60 | 0;
      m = m == 0 ? "00" : m;
      return h == 0 ? `${m} Min` : `${h}:${m} Hrs`;
    },
  },
};
</script>
